<template>
  <div>
      <CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader class="">
      <CRow>
      <CCol col="6" xl="6">
          <h6 class="w-100 text-muted">Typ obsahu</h6>
           <h2 class="mb-0 page-title"><template v-if="contenttype.title">{{contenttype.title}}</template><template v-else>Nový typ obsahu</template></h2>
        </CCol>
        <CCol col="6" xl="6">
             <div class="text-right system-options w-100 d-block" style="">
              <router-link :to="'/content/' + contenttype.slug" v-if="contenttype.title" class="nav-link">
                        <template>
                            <a-button type="primary"> Obsah</a-button>
                        </template>
                    </router-link>
            </div>
        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>

        <div class="container-fluid mt--7" style="margin:0; margin-top:30px" >
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                     <div class="row">
                                        <div class="col-lg-12">
                                          <a-form-item label="Názov">
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.title"
                                            /></a-form-item>
                                        </div>
                                        <div class="col-lg-12">
                                          <a-form-item label="Slug">
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.slug"
                                            />
                                          </a-form-item>
                                        </div>

                                    <div class="col-lg-12" v-if="productCat.length > 0">
                                      <a-form-item label="Kategória produktu">
                                            <a-select defaultValue="1" v-model="contenttype.product" :options="productCat">
                                                 <template v-for="item in productCat">
                                                 <a-select-option :key="item.value" :value="item.value" >{{item.label}}</a-select-option></template>
                                            </a-select>
                                            </a-form-item>
                                      </div>
                                    <div class="col-lg-12" v-if="ctnodes">
                                      <a-form-item label="Breadcrumbs">
                                        <a-select v-if="ctnodes" mode="multiple" v-model="contenttype.breadcrumbs" :options="ctnodes"/>
                                      </a-form-item>
                                    </div>
                                        <div class="col-lg-12" v-if="fieldStoShow">
                                         <a-form-item label="Zobraziť polia">
                                            <a-select mode="multiple" v-model="contenttype.showon">
                                                <template v-for="item in fieldStoShow">
                                                 <a-select-option :key="item.value" :value="item.value + ''" >{{item.label}}</a-select-option></template>
                                            </a-select>
                                            </a-form-item>
                                        </div>
                                        <div class="col-lg-12 mb-4" v-if="fieldStoShow">
                                          <a-form-item label="Kategorizácia">
                                            <a-select v-model="contenttype.maincategory">

                                                <a-select-option value="">Nepriradené</a-select-option>
                                                <template v-for="item in contenttype.fields">
                                                 <a-select-option :key="item.id" v-if="item.type == '12'" :value="item.id" >{{item.title}}</a-select-option></template>
                                            </a-select>
                                            </a-form-item>

                                        </div>
                                        <div class="col-lg-12 mb-4">
                                          <a-form-item label="Revízie typu obsahu">
                                            <a-checkbox class="mb-3" v-model="contenttype.revisions">Revízie</a-checkbox>
                                            </a-form-item>
                                        </div>


                                    </div>
                                <div class="text-right">
                                    <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                    <div class="mt-4 order-xl-1" v-if="contenttype.blocks">
                        <a-card shadow type="secondary">
                            <div class="header bg-white border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h3 class="mb-0">Usporiadanie blokov</h3>
                                    </div>
                                    <div class="col-4 text-right">
                                    </div>
                                </div>
                            </div>
                            <draggable @change="updateBlocks" v-bind="dragOptions" v-model="contenttype.blocks" group="newcomponents" @start="drag=true" @end="drag=false" handle=".handle">
                                <transition-group>
                                    <template v-for="(child, index) in contenttype.blocks">
                                        <div :key="':' + index + child.id" class="hideimages element"><span>
                                            <a class="handle"><i class="fad fa-expand-arrows-alt"></i></a><a><em >{{child.component}}</em> <b v-if="child.des">{{child.des | truncate(30)}} </b> <b>({{child.language}})</b> </a>



                                              <router-link :to="'/block/' + child.id" v-if="child.id > 0"  class="" :href="'/block/' + child.id">
                                                  <a-button type="secondary" class="add-button edit-button"  icon="fas fa-pencil-alt">Uprav</a-button>
                                              </router-link>

                                              <router-link :to="'/block/' + child.showon" v-else  class="" :href="'/block/' + child.showon">
                                                  <a-button type="secondary"  class="add-button edit-button"  icon="fas fa-pencil-alt">Uprav</a-button>
                                              </router-link>


                                               </span>
                                        </div>
                                    </template>
                                </transition-group>
                            </draggable>
                            <div class="text-right mt-3">
                                <button class="btn btn-primary" @click="saveReorder">Uložiť</button>
                            </div>  </a-card>
                    </div>

                </div>
                <div class="col-xl-6 order-xl-1">
                   <a-modal :title="'Nové pole'" centered  v-model="addFieldModal" v-if="addFieldModal" @ok="handleNewFieldSubmit">
                        <div class="additional-setup">
                            <div>
                                 <div class="row">
                                    <div class="col-md-12">
                                      <a-form-item label="Názov poľa">
                                        <a-input placeholder="Názov poľa" v-model="newField.title"></a-input>
                                      </a-form-item>
                                    </div>
                                    <div class="col-md-12">
                                      <a-form-item label="Slug">
                                        <a-input placeholder="Slug" v-model="newField.slug"></a-input>
                                      </a-form-item>
                                    </div>
                                    <div class="col-md-12" v-if="!parseInt(newField.id)">
                                      <a-form-item label="Typ poľa">
                                        <a-select defaultValue="1" v-model="newField.type" @change="handleChange" :options="types" class="w-100" style="min-height:40px;"/>
                                      </a-form-item>
                                    </div>

                                    <template v-if="(newField.type === '8' || newField.type === '22') && !parseInt(newField.id)">
                                        <div class="col-md-12">


       <a-form-item label="Typ obsahu">
      <a-select defaultValue="1" v-model="newField.content_type" class="w-100" style="min-height:40px;">
          <template v-for="k in contenttypes"><a-select-option :key="k.id" :value="k.id">{{k.title}}</a-select-option></template>
      </a-select>   </a-form-item>

                                        </div>
                                    </template>
                                    <template v-if="newField.type === '12' && !parseInt(newField.id)">
                                        <div>
                                            <h6 class="heading-small text-muted mb-4">Slovníky taxonómie</h6>

                                            <a-radio-group v-decorator="['radio-group']" v-model="newField.content_type">
                                                <a-form-item :label="k.title" :key="k" :label-col="{ span: 22 }" :wrapper-col="{ span: 2 }" v-for="k in taxonomies">
                                                    <a-radio class="mb-3" :value="k.id" />
                                                </a-form-item>
                                            </a-radio-group>
                                            <h6 class="heading-small text-muted mb-4">Multi pole</h6>
                                            <a-switch @change="onChange" />
                                        </div>
                                    </template>
                                    <div class="col-md-12 mt-1" v-if="newField.type === '17' && !parseInt(newField.id)">
  <a-form-item
                                                v-for="k in form.getFieldValue('keys')"
                                                :key="k"
                                                v-bind="formItemLayout"
                                                :required="false"

                                        >
                                            <a-input  v-model="newField.fieldcolection.title[k]" class="w-100" placeholder="Názov"/>
                                            <a-select defaultValue="1" :options="types" class="w-100"  v-model="newField.fieldcolection.type[k]" placeholder="Typ poľa"/>
<div v-if="newField.fieldcolection.type[k] === '12'">
<a-select defaultValue="1" :options="taxs" v-model="newField.fieldcolection.setup[k]" class="w-100"  placeholder="Vyberte slovník taxonómie"></a-select>
</div>
                                            <a-icon
                                                    v-if="form.getFieldValue('keys').length > 1"
                                                    class="dynamic-delete-button"
                                                    type="minus-circle-o"
                                                    :disabled="form.getFieldValue('keys').length === 1"
                                                    @click="() => remove(k)"
                                            />
                                        </a-form-item>
                                        <a-form-item>
                                            <a-button type="dashed" style="width: 60%; float:left" @click="add">
                                                <a-icon type="plus" /> Pridaj pole
                                            </a-button>
                                        </a-form-item>
                                    </div>
                                    <div class="col-md-12" v-if="newField.type === '2' && !parseInt(newField.id)">
                                        <a-form-item
                                                v-for="k in form.getFieldValue('keys')"
                                                :key="k"
                                                v-bind="formItemLayout"
                                                style="margin-top:10px;"
                                                :required="false"

                                        >
                                            <a-input style="float:left;width:45%; margin-right:2%;" v-model="newField.options[k].title" placeholder="Názov"/>
                                            <a-input style="float:left;width:45%;" v-model="newField.options[k].slug" placeholder="Slug"/>

                                            <a-icon
                                                    v-if="form.getFieldValue('keys').length > 1"
                                                    class="dynamic-delete-button"
                                                    style="position:relative;left:10px;top:-3px;"
                                                    type="minus-circle-o"
                                                    :disabled="form.getFieldValue('keys').length === 1"
                                                    @click="() => remove(k)"
                                            />
                                        </a-form-item>
                                        <a-form-item >
                                            <a-button type="primary" style="margin-top:20px;text-align:left;" @click="add"> + Pridať</a-button>
                                        </a-form-item>
                                    </div>
                                </div>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.multibool" >Multirozmerné pole</a-checkbox>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.translatablebool" >Samostatne preložiteľné pole</a-checkbox>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.independentbool" >Jazykovo nezávislá položka</a-checkbox>
                                <a-checkbox class="mb-3 mt-4" v-model="newField.filterablebool" >Filtrovateľná položka</a-checkbox>
                            </div>
                        </div>
                    </a-modal>





                    <a-modal :title="'Nová skupina'" centered  v-model="addGroupModal" v-if="addGroupModal" @ok="handleNewGroupSubmit">
                        <div class="additional-setup">
                            <div>
                                <div class="row">
                                    <div class="col-md-12" v-if="grouping">
                                        <a-form-item>
                                            <a-select defaultValue="1" v-model="groupField.groupID" :options="grouping" class="w-100"/>
                                        </a-form-item>
                                    </div>
                                    </div>

                            </div>
                        </div>
                    </a-modal>


                    <a-card shadow type="secondary" v-if="contenttype.title">
                        <div class="header  border-0">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h3 class="mb-0">Polia</h3>
                                </div>
                                <div class="col-6 text-right">
                                    <a-button type="primary" class="float-right ml-2"  @click="openFieldModal">Pridať pole</a-button>
                                    <a-button type="primary" class="float-right"  @click="openGroupModal">Pridať skupinu</a-button>
                                </div>
                            </div>
                        </div>

<div>
    <div class="control_wrapper mt-4">
        <ejs-treeview  id='treeview' :nodeDragStop='dragStop' :fullRowSelect='false'
                      :fullRowNavigable='false' :nodeDragging='nodeDrag' @dataSourceChanged="updateFields" v-model="fields"
                      :nodeTemplate="treeTemplate"  :allowEditing='false' :fields="fields" :allowDragAndDrop='true' :nodeClicked='nodeclicked'>
            <ejs-contextmenu id='contentmenutree' target='#treeview' :items='menuItems' :beforeOpen='beforeopen' :select='menuclick'></ejs-contextmenu>
        </ejs-treeview>
    </div>
</div>

                    </a-card>
                </div>
            </div>

        </div>
        </CCardBody>

        </CCard>
    </CCol>
    </CRow>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import draggable from "vuedraggable";
    import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
    import treeTemplateVueFields from "./content/tree-templateField.vue";
    Vue.use(TreeViewPlugin);
    import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
    Vue.use(ContextMenuPlugin);
    Vue.use(FormItem);
    const columns = [
        {
            title: 'Názov menu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    const columns2 = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Typ poľa',
            dataIndex: 'type',
            scopedSlots: {customRender: 'cttype'},
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    let id = 0;
    export default {
        name: 'contenttype',
        components: {AFormItem, draggable},
        props: ['id'],
        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                treeTemplate: function() {
                    return {
                        template: treeTemplateVueFields
                    };
                },
                menuItems : [
                    { text: 'Upraviť' },
                    { text: 'Zmazať' },
                ],
                fields: {},
                newField: {
                    title : null,
                    type : null,
                    blocks: [],
                    options : [{title: '', slug: ''}],
                    fieldcolection: {'title':[],'type':[],'setup':[]},
                    multi: false,
                    multibool: false,
                    independentbool: false,
                    filterablebool: false,
                    translatablebool: false,
                    slug : null,
                    content_id : this.id
            },  groupField: {
                    groupID : null,
                    content_id : this.id
                },
                loading: false,
                ctnodes:{},
                fieldStoShow:{},
                allFields:{},
                addFieldModal:false,
                addGroupModal:false,
                columns,
                columns2,
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                    {"value" : '8' ,"label" : 'Priradenie pod typ obsahu'},
                    {"value" : '9' ,"label" : 'Dynamická hodnota'},
                    {"value" : '10' ,"label" : 'BetaEditor'},
                    {"value" : '11' ,"label" : 'Galéria obrázkov'},
                    {"value" : '12' ,"label" : 'Priradenie pod taxonómiu'},
                    {"value" : '13' ,"label" : 'Priradenie pod taxonómiu s násobkom'},
                    {"value" : '14' ,"label" : 'Dátum'},
                    {"value" : '15' ,"label" : 'Multipole - vlastnosti'},
                    {"value" : '16' ,"label" : 'Dátum od - do'},
                    {"value" : '17' ,"label" : 'Multipole'},
                    {"value" : '18' ,"label" : 'Ikona'},
                    {"value" : '19' ,"label" : 'Súbor'},
                    {"value" : '20' ,"label" : 'Skupina'},
                    {"value" : '21' ,"label" : 'Mapa'},
                    {"value" : '22', "label" : 'Priradenie konkrétneho obsahu'}
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                  contenttypeId: {
                    uid: this.id
                },
                contenttypes: [],
                productCat: {},
                grouping: {},
                taxs:{},
                contenttype: {
                    title: '',
                    type: '',
                    product : '',
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },       computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            nodeclicked: function(args) {
                if (args.event.which === 3) {
                    var treeObj = document.getElementById('treeview').ej2_instances[0];
                    treeObj.selectedNodes = [args.node.getAttribute('data-uid')];
                }
            },menuclick: function(args) {
                var treevalidate = document.getElementById('treeview').ej2_instances[0];
                var targetNodeId = treevalidate.selectedNodes[0];
                if (args.item.text === "Upraviť") {
                this.openFieldModal(this.allFields[targetNodeId]);
                }
                else if (args.item.text === "Zmazať") {
                    this.confirm(targetNodeId);
                }
            },
            beforeopen: function() {
                var treevalidate = document.getElementById('treeview').ej2_instances[0];
                var targetNodeId = treevalidate.selectedNodes[0];
                var targetNode = document.querySelector('[data-uid="' + targetNodeId + '"]');
                var contentmenutree = document.getElementById('contentmenutree').ej2_instances[0];
                if (targetNode.classList.contains('remove')) {
                    contentmenutree.enableItems(['Zmazať'], false);
                }
                else {
                    contentmenutree.enableItems(['Zmazať'], true);
                }
            },
            nodeDrag(args) {

              //  console.log(args);
                if (args.droppedNode.getElementsByClassName('icon-20').length === 0 && args.droppedNode != null && args.dropIndicator === 'e-drop-in') {
                  args.dropIndicator = 'e-no-drop';
              }
            },
            dragStop(args) {

   if (args.droppedNode.getElementsByClassName('icon-20').length === 0 && args.dropIndicator === 'e-no-drop') {
                        args.cancel = true;
                    }

            },
            updateFields(e)
            {
                let sendata = e.data;
                dataService.axiosPost('reordergroupfields/' + this.id, sendata).then(() => {
                });
            },
            openFieldModal(id = false){
if(parseInt(id.id) > 0)
{
    this.newField = id;
}
else {
    this.newField = {title : null,type : null, blocks: [],options : [{title: '',slug: ''}], fieldcolection: {'title':[],'type':[],'setup':[]}, multi: false, slug : null,content_id : this.id };
}
              this.addFieldModal = true;
            },
            openGroupModal(id = false){
                if(parseInt(id.id) > 0)
                {
                    this.groupField.groupID = id;
                }
                else {
                    this.groupField = { groupID : null, content_id : this.id  };
                }
                this.addGroupModal = true;
            },
            confirm(e) {
                dataService.axiosDelete('contenttypefields', e).then(results => {
                    if (results) {
                     this.fetch();
                    }
                });
            },
            onChange(checked) {
                this.newField.multi = checked;
            },
            cancel() {
            },
            updateOrderFields()
            {
                dataService.axiosPost('reorderfields/' + this.id, this.contenttype.fields).then(() => {

                });
            },
            saveReorder()
            {
                dataService.axiosPost('reorderblocks/' + this.id, this.contenttype.blocks).then(() => {

                });
                    },
            handleChange() {

            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },

            updateBlocks() {

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
this.newField.options.push({ title: '', slug: ''});

                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            handleSubmit() {
                this.contenttype.multi = this.contenttype.multibool;
                let $contenttype_data = this.contenttype;

                dataService.axiosPost('contenttype', $contenttype_data).then(results => {

                    if(results.data)
                    {

                        if (isNaN(this.id)) {  this.contenttype = {};this.$router.push('/content-type/'+results.data.message) }

                    }

                    this.addFieldModal = false;
                });
            },
            handleNewGroupSubmit()
            {
                this.addFieldModal = false;
                let $contenttype_data = this.groupField;
                dataService.axiosPost("contenttype/newGroup", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                     this.fetch();
                    }
                });
            },
            handleNewFieldSubmit()
            {  this.addFieldModal = false;
                this.newField.multi = this.newField.multibool;
                this.newField.independent = this.newField.independentbool;
                this.newField.filterable = this.newField.filterablebool;

                this.newField.translatable = this.newField.translatablebool;
                let $contenttype_data = this.newField;
                dataService.axiosPost("contenttype/newfield", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                        this.fetch();
                    }
                });
            },
            fetch()
            {
                dataService.axiosFetch('contenttype', this.id).then(results => {
                    this.contenttype = results;

                    this.fields = { dataSource: this.contenttype.groups, id: 'id', text: 'url', parentId: 'pid',iconCss: 'icon', url: 'name',child: 'child', selected: 'isSelected' };
                    var roleops = [];
                    results.fields.forEach(obj => { roleops.push({"value" : obj.id + '' , "label" : obj.title}); });

                    this.fieldStoShow = roleops;

                    roleops = [];
                    results.fields.forEach(obj => {

                        roleops[obj.id] = obj;

                    });

                    this.allFields = roleops;


                });
            }
        },
        mounted() {
            if (!isNaN(this.id)) {
         this.fetch();
            }
            dataService.axiosFetch('taxonomies').then(results => {
                this.taxonomies = results;
                  var roleops = [];
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
this.taxs = roleops;
                this.loading = false;
            });
            dataService.axiosFetch('listof','basic_page').then(results => {

                this.ctnodes = results;
                this.loading = false;
            });

            dataService.axiosFetch('taxonomyitems',"product_category").then(results => {

                var roleops = [];
                roleops.push({"value" : '0' , "label" : 'Nie je produkt'});
                results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.productCat = roleops;
                this.loading = false;
            });


            dataService.axiosFetch('taxonomyitems',"grouping").then(results => {

                var roleops = [];
                 results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                this.grouping = roleops;
            });

            dataService.axiosFetch("contenttypes").then(results => {
                this.contenttypes = results;
                this.loading = false;
            });
        }
    };
</script>
<style scoped>
    @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text
    {width:100%;}

   .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-hover > .e-text-content
    {box-shadow:0 0;
    border-color:transparent;}
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text .treeviewdiv
    {
        width:100%;  padding-top:5px;border-top:1px solid #f1f1f1;
    }
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text .treeviewdiv:hover
    {color:#000;}
    .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content
    {box-shadow:0 0;
        border-color:transparent;}
    .control_wrapper >>> .e-treeview .e-list-item.e-active > .e-text-content .e-list-text
    {color: #525f7f;}
    .control_wrapper >>> .e-treeview .e-list-icon,.control_wrapper >>>  .e-treeview .e-list-img
    {display:none}
    .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item > .e-text-content
    {padding:0;background-color:transparent!important;color:#525f7f!important;}
    .control_wrapper >>> .e-treeview .e-ul:not(.e-list-parent)
    {padding:0;}
    .control_wrapper >>> .e-treeview > .e-ul
    {overflow-x:hidden;
    max-width:100%;}

    .control_wrapper >>> .e-treeview .e-ul .tree-column
    {width:33.33%;}

    .control_wrapper >>> .e-treeview .e-ul .type-20 .treeviewdiv
    {background-color: #ffffff; color:#525f7f!important;padding-left:5px;
    }
    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .element
    {display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}

    .element
    {
        padding:0;
        border-radius:0;border: 1px solid #eeeeee;
        margin-bottom:-1px;
    }
    .ant-table-body
    {width:100%}
    .manager
    {width:800px}
    .top-holder
    {width:100%;border:1px solid #eceff3; padding:15px   }
    .element-image
    {width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}

    .element .handle
    {font-size:18px;padding:5px; padding-right:15px;
        display: inline-block;}
    .element span
    {width:100%; display:block; text-align:left; min-height:30px; font-size:12px; background-color: #fff;}
    .edit-button
    {float:right;  border-radius:0px;   border: 0;
        padding-top:4px;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
